import React, { useState } from "react";
import { FaWalking } from "react-icons/fa";
import { BsFillPlusSquareFill } from "react-icons/bs";
import ShowMap from "./buildings/ShowMap";
import StreetViewLink from "./StreetViewLink";
import SunlitNextHours from "./SunlitNextHours";

export default function RestaurantListItem(props) {
  const [selected, setSelected] = useState(false);

  return (
    <div>
      <div className="restaurantContainer">
        <div key={props.name}>
          {props.website ? (
            <a
              style={{
                fontFamily: "PT Sans",
                fontWeight: "700",
                fontStyle: "italic",
                color: "black",
                fontSize: "24px",
              }}
              href={props.website}
              target="_blank"
              rel="noreferrer noopener"
            >
              {props.name}
            </a>
          ) : (
            <span
              style={{
                fontFamily: "PT Sans",
                fontWeight: "700",
                fontStyle: "italic",
                color: "black",
                fontSize: "24px",
              }}
            >
              {props.name}
            </span>
          )}
          <br />
          <SunlitNextHours restaurant={props.restaurant} />
          <br />
          {props.distance && (
            <div
              style={{
                fontSize: "9px",
                color: "black",
                marginTop: "4px",
                backgroundColor: "lightgray",
                height: "12px",
                width: (1 - (1500 - props.distance) / 1500) * 100 + "%",
              }}
            >
              <FaWalking />
              {Math.round(props.distance)} m
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <BsFillPlusSquareFill
            color={"orange"}
            size={30}
            onClick={() => setSelected(!selected)}
          />
        </div>
      </div>
      {selected && (
        <ShowMap
          longitude={props.longitude}
          latitude={props.latitude}
          nonGroundPosZ={props.nonGroundPosZ}
        />
      )}
      {selected && <StreetViewLink id={props.id} />}
      <hr style={{ borderTop: "1px dotted #ccc" }}></hr>
    </div>
  );
}
