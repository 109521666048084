import Footer from "./Footer";
import { Page } from "react-onsenui";

function About() {
  return (
    <Page>
      <div>
        <br />
        <br />
        <span
          style={{
            fontFamily: "PT Sans",
            fontSize: "32pt",
            fontWeight: "700",
            fontStyle: "italic",
          }}
        >
          Hi! <span className="wave">👋</span>
          <br /> We help you find the closest restaurant with outdoor seating
          where you can enjoy the sun the next few hours
        </span>
        <span style={{ fontSize: "32pt" }}> 😎</span>
        <br />
        <br />

        <span style={{ fontSize: "22pt", fontStyle: "italic" }}>
          We use detailed models of cities to calculate any shade generated by
          buildings that might affect the restaurants and their terraces.
        </span>
        <br />
        <br />
        <br />
        <br />
        <video
          muted
          autoPlay
          playsInline
          width="75%"
          src="sunlit_today_demo.MP4"
          type="video/mp4"
        />
        <br />
        <br />
        <span style={{ fontSize: "14pt", fontStyle: "italic" }}>
          <br />
          Currently we cover the following cities:
          <br />
          🇸🇪 Stockholm
          <br />
          <br />
        </span>

        <a href="/">
          <button className="button-74">Find sunny restaurants nearby!</button>
        </a>
        <br />

        <br />
        <br />
        <br />
        <br />

        <span style={{ fontSize: "10pt", fontStyle: "italic" }}>
          We get info about restaurants with outdoor seating from OpenStreetMap.
          Any restaurant with Outdoor seating (outdoor_seating=yes) is included.
          <br />
          <br /> Can't find your local restaurant?
          <br />
          <a href="https://www.openstreetmap.org/fixthemap"           target="_blank"
          rel="noreferrer noopener"
>
            Help fix the map!
          </a>
          <br />
          <br />
        </span>
        <Footer />
      </div>
    </Page>
  );
}

export default About;
