import { useParams } from "react-router-dom";
import Restaurants from "./Restaurants";
import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import { Page } from "react-onsenui";

export default function Location() {
  const { long, lat } = useParams();
  const [coords, setCoordinates] = useState();

  useEffect(() => {
    setCoordinates({ longitude: long ? long : 0.0, latitude: lat ? lat : 0.0 });
  }, [long, lat]);

  return (
    <div className="App">
      <header className="App-header">
        <Page>
          <Restaurants
            coords={coords}
            includeAll={false}
            isGeolocationAvailable
            isGeolocationEnabled
          />
        </Page>
      </header>
      <a href="/">
        <button class="button-74">Find sunny restaurants nearby!</button>
      </a>
      <br />
      <Footer />
    </div>
  );
}
