import Proj4 from "proj4";

// Stockholm
Proj4.defs(
  "EPSG:3011",
  "+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);
// Gothenburg
Proj4.defs(
  "EPSG:3007",
  "+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
);

export default class LocalProjectionCoordConverter {
  constructor(long, lat) {
    this.selectAppropriateLocalProjection(long, lat);
  }

  localProjection = null;

  isPointWithinBoundingBox(
    pointX,
    pointY,
    withinX1,
    withinY1,
    withinX2,
    withinY2
  ) {
    return (
      withinX1 <= pointX &&
      pointX <= withinX2 &&
      withinY1 <= pointY &&
      pointY <= withinY2
    );
  }

  selectAppropriateLocalProjection(long, lat) {
    this.localProjection = this.isPointWithinBoundingBox(
      long,
      lat,
      17.6,
      59.2,
      18.4,
      59.5
    )
      ? "EPSG:3011"
      : "EPSG:3007";
    return this.localProjection;
  }

  getLocalProjectionCoordinates(long, lat) {
    return Proj4(this.localProjection, [long, lat]);
  }

  getLongLatFromLocalCoordinates(x, y) {
    return Proj4(this.localProjection, "WGS84", [y, x]);
  }
}

